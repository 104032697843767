.graphiql-explorer-root > :first-child {
  overflow: hidden !important;
}

[type='button'],
[type='reset'],
[type='submit'],
button {
  -webkit-appearance: button;
  appearance: button;
  background-color: transparent;
  background-image: none;
  border: 0;
}
